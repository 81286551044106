// src/components/Portfolio.js
import React from 'react';
import './styles/styles.css';

function Portfolio() {
  return (
    <section id="portfolio" className="pageLayout">
      <h2>Our Portfolio</h2>
      <div className="portfolio-items">
        <div className="portfolio-item">
          <img src="project1.jpg" alt="" />
          <h3>Project One</h3>
        </div>
        <div className="portfolio-item">
          <img src="project2.jpg" alt="" />
          <h3>Project Two</h3>
        </div>
        <div className="portfolio-item">
          <img src="project3.jpg" alt="" />
          <h3>Project Three</h3>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
