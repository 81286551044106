// src/components/Contact.js
import React from 'react';


import './styles/styles.css';

function Contact() {
  return (
    <section id="contact" className="pageLayout">
      <h2>Contact Us</h2>
      <div className="form__service_card">
      <form className='form__data' name='contact' method='post'>
      <input type="hidden" name="form-name" value="contact" />
        <div className="form-group">
          <label>Name</label>
          <input type="text" name="name" required />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" name="email" required />
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea name="message" required></textarea>
        </div>
        <button type="submit" className="cta-button">Get in Touch</button>       
        </form>
      </div>
      
    </section>
  );
}

export default Contact;


