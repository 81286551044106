// src/App.js
import React, { useEffect } from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import MobileMenu from './scripts/modules/MobileMenu';

function App() {

  useEffect(() => {
    // eslint-disable-next-line 
    const delayMenuInit = setTimeout(() => {
      // eslint-disable-next-line 
      const mobileMenu = new MobileMenu();
  }, 100);

    return () => clearTimeout(delayMenuInit);
}, []);
 
  return (
    
  <Router>
      <Header />
      <Routes>
        <Route path='/' exact element={< Home />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/about' element={<About />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contact' element= {<Contact />} />
      </Routes>
      <Footer />
  </Router>
      
  );
  
}
export default App;
