// src/components/About.js
import React from "react";
import { Link } from 'react-router-dom';
import "./styles/styles.css";
import { Helmet } from 'react-helmet';

// load images

import webDeveloper from './images/webdeveloper.jpg';
import ourMission from './images/ourMission.jpg';
import ourValues from './images/ourValues.jpg';
import whatWeOffer from './images/whatweOffer.jpg';


function About() {
  return (
    
    <section id="about" className="pageLayout"> 
               <>
               <Helmet>
                   <title>Web Design Services | Rose Web Designs</title>
                   <meta name="description" content="Expert web design services to enhance your business's online presence." />
                   <meta name="keywords" content="web design, SEO, responsive design, digital marketing" />

                   <script type="application/ld+json">
           {`
               {
                   "@context": "https://schema.org",
                   "@type": "WebSite",
                   "name": "Rose Web Designs",
                   "url": "https://rosewebdesigns.co.uk"
               }
           `}
       </script>

               </Helmet>
               {/* Page content */}
           </>
    
      <h2>About Us</h2>

      <div className="pageLayout__service_cards">
        <div className="pageLayout__service_card">
            <img className="serviceImage" src={webDeveloper} alt="" />
            <h3>Developing for You</h3>
          <p>
            We are a team of passionate developers dedicated to creating innovative
            and user-friendly web applications. Our goal is to build tools that make
            your online experience more efficient, enjoyable, and secure.
          </p>
        </div>
        <div className="pageLayout__service_card">
          <img className="serviceImage" src={ourMission} alt="" />
          <h3>Our Mission</h3>
          <p>
            To empower individuals and businesses alike by providing cutting-edge
            web solutions that simplify complex tasks and enhance productivity.
          </p>
        </div>
        <div className="pageLayout__service_card">
          <img className="serviceImage" src={ourValues} alt="" />
          <h3>Our Values</h3>
          <p>
            Innovation: We strive to push the boundaries of technology and explore
            new possibilities. 
            Quality: We are committed to delivering high-quality
            products that meet the highest standards. 
            Collaboration: We believe in the power of teamwork and collaboration to achieve great things.
            Customer Focus: We prioritize our users' needs and strive to exceed their expectations. 
          </p>
        </div>
        <div className="pageLayout__service_card">
          <img className="serviceImage" src={whatWeOffer} alt="" />          <h3>What We Offer</h3>
          <p>
            Web Development: We specialize in creating custom web applications
            tailored to your specific needs. 
            Web Design: We design visually appealing and user-friendly websites that leave a lasting impression.
            Web Hosting: We provide reliable and secure web hosting solutions to keep your website online 24/7. 
            Web Security: We protect your website from cyber threats with advanced security measures.
          </p>
        </div>
      </div>
      <h4>
        We would love to hear from you. Please feel free to contact us with any
        questions or inquiries.
      </h4>
      <p>
        <Link to="/contact" className="cta-button">Get in Touch</Link>
      </p>
    </section>
  );
}

export default About;
