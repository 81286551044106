import React from "react";
import "./styles/styles.css";
import image from "./images/194191051.gif";
import homeImage from "./images/AdobeStock_85774162.jpeg";

function Home() {
  return (
    <section id="home" className="page-section">
      <div className="hero">
        <img src={image} alt="" />
      </div>
      
        <div className="row__container">
          
            <div className="home__col1">
                <img className="hero__homePage-img" src={homeImage} alt="" />
            </div>
            <div className="home__col2">
              <p className="headline--small">
                <span className="headline">Rose Web Designs</span>
                <strong> are dedicated</strong> to crafting visually stunning and
                highly functional websites for businesses of all sizes. Our mission
                is to help you succeed online with innovative design and expert web
                development.
              </p>
            </div>
                 
          </div>
      
    </section>
  );
}


export default Home;