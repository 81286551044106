// src/components/Services.js
import React from "react";
import "./styles/styles.css";
import { Link } from 'react-router-dom';

import webdevImage from './images/71042877_copy.jpg';
import webDesign from './images/AdobeStock_81209815.jpeg';
import seoImage from'./images/AdobeStock_87909563.jpeg';
function Services() {
  return (
    <section id="services" className="pageLayout">
      <h2>What We Offer</h2>
      <p>
        Explore our range of professional web solutions tailored to boost your
        online presence.
      </p>
      <div className="pageLayout__service_cards">
        <div className="pageLayout__service_card">
          <img className="serviceImage" src={ webdevImage } alt="" />
          <h3>Web Development</h3>
          <p>
            Crafting responsive, fast, and scalable websites using cutting-edge
            technologies, tailored to your unique business needs.
          </p>
        </div>

        <div className="pageLayout__service_card">
          <img className="serviceImage" src={ webDesign } alt="" />
          <h3>Custom Design</h3>
          <p>
            Eye-catching, intuitive designs that provide a seamless user
            experience while reflecting your brand’s identity.
          </p>
        </div>

        <div className="pageLayout__service_card">
          <img className="serviceImage" src={ seoImage } alt="" />
          <h3>Search Engine Optimization (SEO)</h3>
          <p>
            Strategically optimize your site to increase visibility on major
            search engines and drive organic traffic.
          </p>
        </div>
      </div>
      <p>
        <Link to="/contact" className="cta-button">Get in Touch</Link>
      </p>
    </section>
  );
}

export default Services;
