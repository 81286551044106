// src/components/Footer.js
import React from 'react';
import './styles/styles.css';

function Footer() {
  return (
    <div className='footer'>
      <p>&copy; 2024 Rose Web Designs. All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
