// src/components/Header.js
import React from 'react';

import logo3 from './images/logo3.jpg';

import './styles/styles.css';

function Header() {
  return (
    <header className="site-header">
      <div className="container">
        <div className="site-header__logo">
          <img src={ logo3 } alt=''/>
        </div>

        <div className='site-header__menu-icon'>         
          <div className="site-header__menu-icon__middle"></div>
        </div>        

        <div className="site-header__menu-content">
          <div className="site-header__btn-container">
          <a href='/contact' className='btn'>Get in Touch</a>
          </div>
          <nav className='primary-nav primary-nav--pull-right'>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </nav>
        </div>
      </div>
    </header>
    
  );
}

export default Header;
  